import React from 'react';

import staff from "../staff.json"
import StaffCard from './StaffCard';

const therapistCards = staff.map((provider,i) => {
    console.log(provider)
    if(provider.therapist){
        return <StaffCard key={i} id={i} provider={provider} />
    }
})

const nontherapistCards = staff.map((provider,i) => {
    console.log(provider)
    if(!provider.therapist){
        return <StaffCard key={i} id={i} provider={provider} />
    }
})



function Staff(props) {
    return (
        <div className='Staff'>
            <span className='Scroll-Target Staff-Scroll'></span>
            <h2>Staff</h2>
            <div className='Staff-Flex-Cont'>
                <div>
                    <div style={{padding:"12px"}}></div>
                    <h3 style={{marginBottom:"12px"}}>Clinicians</h3>
                    <div className="Staff-Card-Cont">
                        {therapistCards}
                    </div>
                </div>
                <div>
                    <div style={{padding:"12px"}}></div>
                    <h3 style={{marginBottom:"12px"}}>Administrative Staff</h3> 
                    <div className='Staff-Card-Cont'>
                        {nontherapistCards}
                    </div>
                </div>
            </div>
            <div style={{padding:"24px"}}></div>
            <h3 style={{marginBottom:"12px"}}>Former Clinicians</h3>
            <div className='Former-Clinician'>
                <div style={{padding:"8px"}}></div>
                <h4>Lauri Warren, LCSW</h4>
                <p>Lauri can be contacted at lauriwarren.lcsw@gmail.com or 919-360-7960</p>
            </div>
            <div className='Former-Clinician'>
                <div style={{padding:"8px"}}></div>
                <h4>Donna Boni, MSW, LCSW, LCAS</h4>
                <p>Donna can be contacted at donna@donnaboni.com or 919-338-3959. Her website is www.donnaboni.com</p>
            </div>
            <div className='Former-Clinician'>
                <div style={{padding:"8px"}}></div>
                <h4>Kathy Dobbs, LCSW</h4>
                <p>Kathy has relocated to Missouri. You may contact her at kdobbslcsw@gmail.com</p>
            </div>

        </div>
    );
}

export default Staff;